import { StoreActionProps } from '.';
import { ErrorType, PaymentGatewayStatus } from '../types';

const getAll = async (props: StoreActionProps) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.get(
            `/api/admin/manage-payment-gateway-status/get-all`
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        return response.data as PaymentGatewayStatus[];
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const toggleStatus = async (props: StoreActionProps, id: number) => {
    const { apiService, toasterContext } = props;

    try {
        const apiResponse = await apiService.post(
            `/api/admin/manage-payment-gateway-status/update/${id}`,
            {}
        );
        const response = apiResponse.parsedBody;
        if (!response.status) {
            toasterContext.setToaster({
                isOpen: true,
                message: response.message,
                severity: 'error'
            });
            return null;
        }

        toasterContext.setToaster({
            isOpen: true,
            message: response.message,
            severity: 'success'
        });

        return null;
    } catch (e: ErrorType) {
        toasterContext.setToaster({
            isOpen: true,
            message: e.message,
            severity: 'error'
        });
        return null;
    }
};

const paymentGatewayStatusAction = (props?: StoreActionProps) => {
    if (!props) {
        return null;
    }

    return {
        getAll: () => getAll(props),
        toggleStatus: (id: number) => toggleStatus(props, id)
    };
};

export default paymentGatewayStatusAction;

export interface PaymentGatewayStatusAction {
    paymentGatewayStatusAction: typeof paymentGatewayStatusAction;
}
